import React from 'react'
import './PagIni.css'

import { NavLink } from 'react-router-dom';

// +++ MultiIdiomas +++
import { useTranslation } from "react-i18next";

export function PagIni() {
    const { t } = useTranslation();

    return (
        <>
            <table className="BlPagIni">
                <tr>
                    <td >
                        <pre>
                            <br/><br/>
                            {t('TextPagIni')}<b>{t('FlyJamDev')}</b>{t('TextPagIni1')}<br/>
                            {t('TextPagIni2')}<br/>
                            {t('TextPagIni3')}<b>{t('TextPagIni4')}</b><br/>
                            {t('TextPagIni5')}<br/>
                            <br/>
                            {t('TextPagIni6')}<br/>
                            {t('TextPagIni7')}<br/>
                            {t('TextPagIni8')}<br/>
                        </pre>
                    </td>
                </tr>
                <table className="BlPagInicioApps">
                    <tr>
                        <td>
                            <NavLink to={{ pathname: "/Apps", search: '?app=LanguageTranslatorFast' }}>
                            <div className="RefApps">
                                <b>{t('LanguageTranslatorFast')}</b><br/>
                                    <img alt={t('LanguageTranslatorFast')} title={t('LanguageTranslatorFast')} src="/assets/imgs/logos/Logo_LanguageTranslatorFast.png" />
                                    </div>
                                </NavLink>
                        </td>
                        <td>
                            <NavLink to={{ pathname: "/Apps", search: '?app=CalendarNotes' }}>
                            <div className="RefApps">
                                <b>{t('CalendarNotes')}</b><br/>
                                    <img alt={t('CalendarNotes')} title={t('CalendarNotes')} src="/assets/imgs/logos/Logo_CalendarNotes.png"/>
                                    </div>
                                </NavLink>
                        </td>
                        <td>
                            <NavLink to={{ pathname: "/Apps", search: '?app=CryptApp' }}>
                            <div className="RefApps">
                                <b>{t('CryptApp')}</b><br/>
                                    <img alt={t('CryptApp')} title={t('CryptApp')} src="/assets/imgs/logos/Logo_CryptApp.png"/>
                                    </div>
                                </NavLink>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <NavLink to={{ pathname: "/Apps", search: '?app=NoteApp' }}>
                            <div className="RefApps">
                                <b>{t('NoteApp')}</b><br/>
                                    <img alt={t('NoteApp')} title={t('NoteApp')} src="/assets/imgs/logos/Logo_NoteApp.png" />
                                    </div>
                                </NavLink>
                        </td>
                        <td>
                            <NavLink to={{ pathname: "/Apps", search: '?app=InstantTraductor' }}>
                            <div className="RefApps">
                                <b>{t('InstantTraductor')}</b><br/>
                                    <img alt={t('InstantTraductor')} title={t('InstantTraductor')} src="/assets/imgs/logos/Logo_InstantTraductor.png"/>
                                    </div>
                                </NavLink>
                        </td>                        
                    </tr>

                </table>

                <tr>
                    <td>
                        <br /><br />
                        <pre>
                            {t('TextContactaEmail')}<br/>
                            {t('TextContactaEmail1')}<br/>
                            {t('TextContactaEmail2')}<br/>
                            {t('EmailContact')}<br/>
                        </pre>
                    </td>
                </tr>
            </table>
            <br /><br />
        </>
    )
}
