import React from 'react'
import './PagKnowUs.css'

// +++ MultiIdiomas +++
import { useTranslation } from "react-i18next";

export function PagKnowUs() {
    const { t } = useTranslation();

    return (
        <table className="BlPagIni">
            <tr>
                <td >
                    <pre>
                        <br /><br /><br />
                        <b className='Titulo'>{t('FlyJam')}</b><br />
                        <br />
                        <b>{t('TextPagKnowUs')}<br />
                            {t('TextPagKnowUs1')}<br />
                            {t('TextPagKnowUs2')}</b><br />
                        <br />
                        {t('TextPagKnowUs3')}<br />
                        {t('TextPagKnowUs4')}<br />
                        {t('TextPagKnowUs5')}<br />
                        {t('TextPagKnowUs6')}<br />
                        {t('TextPagKnowUs7')}<br />
                        {t('TextPagKnowUs8')}<br />
                        {t('TextPagKnowUs9')}<br />
                        {t('TextPagKnowUs10')}<br />
                        {t('TextPagKnowUs11')}<br />
                        {t('TextPagKnowUs12')}<br />
                    </pre>
                </td>
                <td >
                    <br /><br /><br />
                    <img class='ImgDevFlyJam' alt={t('FlyJamDev')} title={t('lbInfoConoceAppFlyJam')} src='assets/imgs/logos/Logo_Developer_FlyJam.png' />
                </td>
            </tr>
            <tr>
                <td  >
                    <pre>
                        {t('TextContactaEmail')}<br />
                        {t('TextContactaEmail1')}<br />
                        {t('EmailContact')}<br />
                    </pre>
                </td>
            </tr>
        </table>
    )
}
