import React from 'react'
import './Header.css'

import { useTranslation } from "react-i18next";

import { Link, NavLink } from 'react-router-dom'; // Link y NavLink, hacen lo mismos con la diferencia, de que "NavLink" indica cual de los Link esta activo
// en dicho momento, de esta forma para nuestro menu conseguimos que salga una raya debajo del link en el que el usuario esta activo/navegando actualmente
//      El NavLink pasa al html el valor "class=active" 

import { CambIdio } from '../../GestionIdiomas/SelecIdiomas';

export function Header() {
    const { t } = useTranslation();

    return (
        <header className="Header">
            <div className="Cabecera">
                <div className="LogoWeb">
                    <Link to="/Index" title="{t('LinkIndex')}" rel={t('LinkIndex')}>
                        <img src='/assets/imgs/logos/Logo_FlyJam.png' title={t('lbInfoDesAppDispAndroid')} alt={t('lbInfoDesAppDispAndroid')} />
                    </Link>
                </div>
                <div className="MenuCabecera">
                    <nav className="Navega">
                        <ul>
                            <li><NavLink to="/Index" title={t('lbInicio')} >{t('lbInicio')}</NavLink></li>
                            <li><NavLink to="/Apps" title={t('lbApp')} >{t('lbApp')}</NavLink></li>
                            <li><NavLink to="/VideoTutorials" title={t('lbVideoTuto')} >{t('lbVideoTuto')}</NavLink></li>
                            <li><NavLink to="/KnowUs" title={t('lbConocenos')} >{t('lbConocenos')}</NavLink></li>
                        </ul>
                    </nav>
                </div>
                <div className="LinkRedesSociales">
                    <img src="assets/imgs/iconos/facebook_32.png" title="facebook" alt="facebook" />
                    <img src="assets/imgs/iconos/gplus_32.png" title="gplus" alt="gplus" />
                    <img src="assets/imgs/iconos/twitter_32.png" title="twitter" alt="twitter" />
                </div>
                <CambIdio />
            </div>
        </header>
    )
}
/*

Si quisieramos añadir alguna funcion extra cuando un NavLink este activo/inactivo:
    className={({isActive})=>{ return isActive ? 'active' : 'inactive'} }


Tambien prodriamos crear nuestro Propio "NavLink" algo asi:

++++ +++++ +++++ +++++
import { MyNavLink as NavLinkRealctRouter} from 'react-router-dom'; 

export const MyNavLink = ({ to, children, ...props }) => {
    return (
        <NavLinkRealctRouter 
            { ...props}
            className={({isActive})=> isActive ? 'active' : 'inactive'}
            to={to}
        >{children}</NavLinkRealctRouter 
    )
}
++++ +++++ +++++ +++++
*/