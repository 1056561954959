import React from 'react'
import './PagApps.css'

// +++ MultiIdiomas +++
import { useTranslation } from "react-i18next";

import { NavLink, useLocation } from 'react-router-dom'; // useParams

export function PagApps() {

  const { search } = useLocation();
  let query = new URLSearchParams(search); // Para serializar los parametros
  //console.log(query)

  let appSelecUsu = query.get("app")
  //console.log(">>>" + appSelecUsu)

  return (
    <>
      {appSelecUsu == "LanguageTranslatorFast" && <LanguageTranslatorFast />}
      {appSelecUsu == "CalendarNotes" && <CalendarNotes />}
      {appSelecUsu == "CryptApp" && <CryptApp />}
      {appSelecUsu == "NoteApp" && <NoteApp />}
      {appSelecUsu == "InstantTraductor" && <InstantTraductor />}
      {appSelecUsu == "FlashLight" && <FlashLight />}
      {appSelecUsu == "XplorApp" && <XplorApp />}
      {appSelecUsu !== "LanguageTranslatorFast" && appSelecUsu !== "CalendarNotes" && appSelecUsu !== "CryptApp" && appSelecUsu !== "NoteApp" &&
       appSelecUsu !== "InstantTraductor" && appSelecUsu !== "FlashLight" && appSelecUsu !== "XplorApp" && <SelecInfoApp />}

    </>
  )
}

function LanguageTranslatorFast() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
    <tr>
      <td >
        <pre>
          <br />
          <b className='Titulo' >{t('LanguageTranslatorFast')}</b><br />
          <br />
          <b>{t('lbDescripcion')}</b><br />
          <br />
          {t('TextPagAppsLanguageTranslatorFast')}<br />
          {t('TextPagAppsLanguageTranslatorFast1')}<br />
          {t('TextPagAppsLanguageTranslatorFast2')}<br />
          {t('TextPagAppsLanguageTranslatorFast3')}<br />
          <br />
          {t('TextPagAppsLanguageTranslatorFast4')}<br />
          {t('TextPagAppsLanguageTranslatorFast5')}<br />
          {t('TextPagAppsLanguageTranslatorFast6')}<br />
          {t('TextPagAppsLanguageTranslatorFast7')}<br />
        </pre>
      </td>
      <td>
        <br /><br /><br /><br /><br /><br />
        <div className="BlAutoPubli">
          <b>{t('LanguageTranslatorFast')}</b><br /><img alt={t('LanguageTranslatorFast')} title={t('NoteApp')} src="/assets/imgs/logos/Logo_LanguageTranslatorFast.png" />
        </div>
        <br /><br />
        <div className="Descarga">
          <NavLink to='https://play.google.com/store/apps/details?id=flyjam.LanguageTranslatorFast'>
            <img src="assets/imgs/iconos/playstore_32.png" />
          </NavLink>
        </div>
        <br /><br />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <pre>
          <b>{t('TextPagAppsLanguageTranslatorFast8')}</b><br />
          <br />
          {t('TextPagAppsLanguageTranslatorFast9')}<br />
          {t('TextPagAppsLanguageTranslatorFast10')}<br />
          {t('TextPagAppsLanguageTranslatorFast11')}<br />
          {t('TextPagAppsLanguageTranslatorFast12')}<br />
          {t('TextPagAppsLanguageTranslatorFast13')}<br />
          {t('TextPagAppsLanguageTranslatorFast14')}<br />
          {t('TextPagAppsLanguageTranslatorFast15')}<br />
          <br />
          {t('TextPagAppsLanguageTranslatorFast16')}<br />
          {t('TextPagAppsLanguageTranslatorFast17')}<br />
          {t('TextPagAppsLanguageTranslatorFast18')}<br />
          {t('TextPagAppsLanguageTranslatorFast19')}<br />
          {t('TextPagAppsLanguageTranslatorFast20')}<br />
          {t('TextPagAppsLanguageTranslatorFast21')}<br />
          {t('TextPagAppsLanguageTranslatorFast22')}<br />
          {t('TextPagAppsLanguageTranslatorFast23')}<br />
          {t('TextPagAppsLanguageTranslatorFast24')}<br />
          {t('TextPagAppsLanguageTranslatorFast25')}<br />
          <br />
          {t('TextPagAppsLanguageTranslatorFast26')}<br />
          {t('TextPagAppsLanguageTranslatorFast27')}<br />
          {t('TextPagAppsLanguageTranslatorFast28')}<br />
          {t('TextPagAppsLanguageTranslatorFast29')}<br />
        </pre>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <pre>
          {t('TextContactaEmail')}<br />
          {t('TextContactaEmail1')}<br />
          {t('EmailContact')}<br />
        </pre>
      </td>
    </tr>
  </table>
  )
}

function CalendarNotes() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
      <tr>
        <td >
          <pre>
            <br />
            <b className='Titulo' >{t('CalendarNotes')}</b><br />
            <br />
            <b>{t('lbDescripcion')}</b><br />
            <br />
            {t('TextPagAppsCalendarNotes')}<br />
            {t('TextPagAppsCalendarNotes1')}<br />
            {t('TextPagAppsCalendarNotes2')}<br />
            {t('TextPagAppsCalendarNotes3')}<br />
            <br />
            {t('TextPagAppsCalendarNotes4')}<br />
            {t('TextPagAppsCalendarNotes5')}<br />
            {t('TextPagAppsCalendarNotes6')}<br />
            {t('TextPagAppsCalendarNotes7')}<br />
            {t('TextPagAppsCalendarNotes8')}<br />
            {t('TextPagAppsCalendarNotes9')}<br />
          </pre>
        </td>
        <td>
          <br /><br /><br /><br /><br /><br />
          <div className="BlAutoPubli">
            <b>{t('CalendarNotes')}</b><br /><img alt={t('CalendarNotes')} title={t('CalendarNotes')} src="/assets/imgs/logos/Logo_CalendarNotes.png" />
          </div>
          <br /><br />
          <div className="Descarga">
            <NavLink to='https://play.google.com/store/apps/details?id=flyjam.CalendarNotes'>
              <img src="assets/imgs/iconos/playstore_32.png" />
            </NavLink>
          </div>
          <br /><br />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            <b>{t('TextPagAppsCalendarNotes10')}</b><br />
            <br />
            {t('TextPagAppsCalendarNotes11')}<br />
            {t('TextPagAppsCalendarNotes12')}<br />
            {t('TextPagAppsCalendarNotes13')}<br />
            
          </pre>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            {t('TextContactaEmail')}<br />
            {t('TextContactaEmail1')}<br />
            {t('EmailContact')}<br />
          </pre>
        </td>
      </tr>
    </table>
  )
}

function CryptApp() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
    <tr>
      <td >
        <pre>
          <br />
          <b className='Titulo' >{t('CryptApp')}</b><br />
          <br />
          <b>{t('lbDescripcion')}</b><br />
          <br />
          {t('TextPagAppsCryptApp')}<br />
          {t('TextPagAppsCryptApp1')}<br />
          {t('TextPagAppsCryptApp2')}<br />
          {t('TextPagAppsCryptApp3')}<br />
          {t('TextPagAppsCryptApp4')}<br />
          {t('TextPagAppsCryptApp5')}<br />
          {t('TextPagAppsCryptApp6')}<br />
          <br />
          {t('TextPagAppsCryptApp7')}<br />
          {t('TextPagAppsCryptApp8')}<br />
          {t('TextPagAppsCryptApp9')}<br />
          {t('TextPagAppsCryptApp10')}<br />
          {t('TextPagAppsCryptApp11')}<br />
          <br />
          {t('TextPagAppsCryptApp12')}<br />
          {t('TextPagAppsCryptApp13')}<br />
          {t('TextPagAppsCryptApp14')}<br />
          <br />
          {t('TextPagAppsCryptApp15')}<br />
          {t('TextPagAppsCryptApp16')}<br />
        </pre>
      </td>
      <td>
        <br /><br /><br /><br /><br /><br />
        <div className="BlAutoPubli">
          <b>{t('CryptApp')}</b><br /><img alt={t('CryptApp')} title={t('NoteApp')} src="/assets/imgs/logos/Logo_CryptApp.png" />
        </div>
        <br /><br />
        <div className="Descarga">
          <NavLink to='https://play.google.com/store/apps/details?id=flyjam.CryptApp'>
            <img src="assets/imgs/iconos/playstore_32.png" />
          </NavLink>
        </div>
        <br /><br />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <pre>
          <b>{t('TextPagAppsCryptApp17')}</b><br />
          <br />
          {t('TextPagAppsCryptApp18')}<br />
          {t('TextPagAppsCryptApp19')}<br />
          {t('TextPagAppsCryptApp20')}<br />
          {t('TextPagAppsCryptApp21')}<br />
          {t('TextPagAppsCryptApp22')}<br />
          {t('TextPagAppsCryptApp23')}<br />
          {t('TextPagAppsCryptApp24')}<br />
          {t('TextPagAppsCryptApp25')}<br />
          {t('TextPagAppsCryptApp26')}<br />
          {t('TextPagAppsCryptApp27')}<br />
          {t('TextPagAppsCryptApp28')}<br />
          {t('TextPagAppsCryptApp29')}<br />
          <br />
          {t('TextPagAppsCryptApp30')}<br />
          {t('TextPagAppsCryptApp31')}<br />
          {t('TextPagAppsCryptApp32')}<br />
          {t('TextPagAppsCryptApp33')}<br />
          {t('TextPagAppsCryptApp34')}<br />
          {t('TextPagAppsCryptApp35')}<br />
        </pre>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <pre>
          {t('TextContactaEmail')}<br />
          {t('TextContactaEmail1')}<br />
          {t('EmailContact')}<br />
        </pre>
      </td>
    </tr>
  </table>
  )
}

function NoteApp() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
      <tr>
        <td >
          <pre>
            <br />
            <b className='Titulo' >{t('NoteApp')}</b><br />
            <br />
            <b>{t('lbDescripcion')}</b><br />
            <br />
            {t('TextPagAppsNoteApp')}<br />
            {t('TextPagAppsNoteApp1')}<br />
            {t('TextPagAppsNoteApp2')}<br />
            {t('TextPagAppsNoteApp3')}<br />
            {t('TextPagAppsNoteApp4')}<br />
            {t('TextPagAppsNoteApp5')}<br />
          </pre>
        </td>
        <td>
          <br /><br /><br /><br /><br /><br />
          <div className="BlAutoPubli">
            <b>{t('NoteApp')}</b><br /><img alt={t('NoteApp')} title={t('NoteApp')} src="/assets/imgs/logos/Logo_NoteApp.png" />
          </div>
          <br /><br />
          <div className="Descarga">
            <NavLink to='https://play.google.com/store/apps/details?id=flyjam.NoteApp'>
              <img src="assets/imgs/iconos/playstore_32.png" />
            </NavLink>
          </div>
          <br /><br />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            <b>{t('TextPagAppsNoteApp6')}</b><br />
            <br />
            {t('TextPagAppsNoteApp7')}<br />
            {t('TextPagAppsNoteApp8')}<br />
            <br />
            {t('TextPagAppsNoteApp9')}<br />
            {t('TextPagAppsNoteApp10')}<br />
            <br />
            {t('TextPagAppsNoteApp11')}<br />
            {t('TextPagAppsNoteApp12')}<br />
            <br />
            {t('TextPagAppsNoteApp13')}<br />
            {t('TextPagAppsNoteApp14')}<br />
            <br />
            {t('TextPagAppsNoteApp15')}<br />
            {t('TextPagAppsNoteApp16')}<br />
            <br />
            {t('TextPagAppsNoteApp17')}<br />
            {t('TextPagAppsNoteApp18')}<br />
          </pre>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            {t('TextContactaEmail')}<br />
            {t('TextContactaEmail1')}<br />
            {t('EmailContact')}<br />
          </pre>
        </td>
      </tr>
    </table>
  )
}

function InstantTraductor() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
    <tr>
      <td >
        <pre>
          <br />
          <b className='Titulo' >{t('InstantTraductor')}</b><br />
          <br />
          <b>{t('lbDescripcion')}</b><br />
          <br />
          {t('TextPagAppsInstantTraductor')}<br />
          {t('TextPagAppsInstantTraductor1')}<br />
          {t('TextPagAppsInstantTraductor2')}<br />
          {t('TextPagAppsInstantTraductor3')}<br />
          <br />
          {t('TextPagAppsInstantTraductor4')}<br />
          {t('TextPagAppsInstantTraductor5')}<br />
          {t('TextPagAppsInstantTraductor6')}<br />
          {t('TextPagAppsInstantTraductor7')}<br />
        </pre>
      </td>
      <td>
        <br /><br /><br /><br /><br /><br />
        <div className="BlAutoPubli">
          <b>{t('InstantTraductor')}</b><br /><img alt={t('InstantTraductor')} title={t('NoteApp')} src="/assets/imgs/logos/Logo_InstantTraductor.png" />
        </div>
        <br /><br />
        <div className="Descarga">
          <NavLink to='https://play.google.com/store/apps/details?id=flyjam.InstantTraductor'>
            <img src="assets/imgs/iconos/playstore_32.png" />
          </NavLink>
        </div>
        <br /><br />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <pre>
          <b>{t('TextPagAppsInstantTraductor8')}</b><br />
          <br />
          {t('TextPagAppsInstantTraductor9')}<br />
          {t('TextPagAppsInstantTraductor10')}<br />
          {t('TextPagAppsInstantTraductor11')}<br />
          {t('TextPagAppsInstantTraductor12')}<br />
          {t('TextPagAppsInstantTraductor13')}<br />
          {t('TextPagAppsInstantTraductor14')}<br />
          {t('TextPagAppsInstantTraductor15')}<br />
          {t('TextPagAppsInstantTraductor16')}<br />
          <br />
          {t('TextPagAppsInstantTraductor17')}<br />
          {t('TextPagAppsInstantTraductor18')}<br />
          {t('TextPagAppsInstantTraductor19')}<br />
          {t('TextPagAppsInstantTraductor20')}<br />
          {t('TextPagAppsInstantTraductor21')}<br />
          {t('TextPagAppsInstantTraductor22')}<br />
          {t('TextPagAppsInstantTraductor23')}<br />
          {t('TextPagAppsInstantTraductor24')}<br />
          {t('TextPagAppsInstantTraductor25')}<br />
          {t('TextPagAppsInstantTraductor26')}<br />
          <br />
          {t('TextPagAppsInstantTraductor27')}<br />
          {t('TextPagAppsInstantTraductor28')}<br />
          {t('TextPagAppsInstantTraductor29')}<br />
          {t('TextPagAppsInstantTraductor30')}<br />
        </pre>
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <pre>
          {t('TextContactaEmail')}<br />
          {t('TextContactaEmail1')}<br />
          {t('EmailContact')}<br />
        </pre>
      </td>
    </tr>
  </table>
  )
}

function FlashLight() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
      <tr>
        <td >
          <pre>
            <br />
            <b className='Titulo' >{t('FlashLight')}</b><br />
            <br />
            <b>{t('lbDescripcion')}</b><br />
            <br />
            {t('TextPagAppsFlashLight')}<br />
            {t('TextPagAppsFlashLight1')}<br />
            {t('TextPagAppsFlashLight2')}<br />
            {t('TextPagAppsFlashLight3')}<br />
            {t('TextPagAppsFlashLight4')}<br />
            {t('TextPagAppsFlashLight5')}<br />
            {t('TextPagAppsFlashLight6')}<br />
            {t('TextPagAppsFlashLight7')}<br />
          </pre>
        </td>
        <td>
          <br /><br /><br /><br /><br /><br />
          <div className="BlAutoPubli">
            <b>{t('FlashLight')}</b><br /><img alt={t('FlashLight')} title={t('FlashLight')} src="/assets/imgs/logos/Logo_FlashLight.png" />
          </div>
          <br /><br />
          <div className="Descarga">
            <NavLink to='https://play.google.com/store/apps/details?id=flyjam.FlashLight'>
              <img src="assets/imgs/iconos/playstore_32.png" />
            </NavLink>
          </div>
          <br /><br />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            <b>{t('TextPagAppsFlashLight8')}</b><br />
            <br />
            {t('TextPagAppsFlashLight9')}<br />
            {t('TextPagAppsFlashLight10')}<br />
            {t('TextPagAppsFlashLight11')}<br />
            {t('TextPagAppsFlashLight12')}<br />            
          </pre>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            {t('TextContactaEmail')}<br />
            {t('TextContactaEmail1')}<br />
            {t('EmailContact')}<br />
          </pre>
        </td>
      </tr>
    </table>
  )
}
function XplorApp() {
  const { t } = useTranslation();

  return (
    <table className="BlPagIni">
      <tr>
        <td >
          <pre>
            <br />
            <b className='Titulo' >{t('XplorApp')}</b><br />
            <br />
            <b>{t('lbDescripcion')}</b><br />
            <br />
            {t('TextPagAppsXplorApp')}<br />
            {t('TextPagAppsXplorApp1')}<br />
            {t('TextPagAppsXplorApp2')}<br />
            {t('TextPagAppsXplorApp3')}<br />
            {t('TextPagAppsXplorApp4')}<br />
            {t('TextPagAppsXplorApp5')}<br />
            {t('TextPagAppsXplorApp6')}<br />
            {t('TextPagAppsXplorApp7')}<br />

            {t('TextPagAppsXplorApp8')}<br />
            {t('TextPagAppsXplorApp9')}<br />
            {t('TextPagAppsXplorApp10')}<br />
          </pre>
        </td>
        <td>
          <br /><br /><br /><br /><br /><br />
          <div className="BlAutoPubli">
            <b>{t('XplorApp')}</b><br /><img alt={t('XplorApp')} title={t('XplorApp')} src="/assets/imgs/logos/Logo_XplorApp.png" />
          </div>
          <br /><br />
          <div className="Descarga">
            <NavLink to='https://play.google.com/store/apps/details?id=flyjam.XplorApp'>
              <img src="assets/imgs/iconos/playstore_32.png" />
            </NavLink>
          </div>
          <br /><br />
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            <b>{t('TextPagAppsXplorApp11')}</b><br />
            <br />
            {t('TextPagAppsXplorApp12')}<br />
            {t('TextPagAppsXplorApp13')}<br />
            {t('TextPagAppsXplorApp14')}<br />
            {t('TextPagAppsXplorApp15')}<br />
            {t('TextPagAppsXplorApp16')}<br />
            {t('TextPagAppsXplorApp17')}<br />
            {t('TextPagAppsXplorApp18')}<br />
            {t('TextPagAppsXplorApp19')}<br />
            {t('TextPagAppsXplorApp20')}<br />
            {t('TextPagAppsXplorApp21')}<br />
            {t('TextPagAppsXplorApp22')}<br />
          </pre>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <pre>
            {t('TextContactaEmail')}<br />
            {t('TextContactaEmail1')}<br />
            {t('EmailContact')}<br />
          </pre>
        </td>
      </tr>
    </table>

  )
}

function SelecInfoApp() {
  const { t } = useTranslation();

  return (
    <div className="ListCascada">
      <ul>
        <li className="Der">
          <div className="Imagen">
            <NavLink to={{ pathname: "/Apps", search: '?app=LanguageTranslatorFast' }}>
              <img src="assets/imgs/logos/Logo_LanguageTranslatorFast.png" alt={t('LanguageTranslatorFast')} title={t('LanguageTranslatorFast')} />
            </NavLink>
          </div>
          <div className="Titulo">
            <NavLink to={{ pathname: "/Apps", search: '?app=LanguageTranslatorFast' }}>{t('LanguageTranslatorFast')}</NavLink>
          </div>
          <div className="Descrip">
            <p>{t('TextPagApps_InfoLanguageTranslatorFast')}
              <br />{t('TextPagApps_InfoLanguageTranslatorFast1')}
              <br />{t('TextPagApps_InfoLanguageTranslatorFast2')}</p>
          </div>
        </li>

        <li className="Izq">
          <div class="Imagen">
            <NavLink to={{ pathname: "/Apps", search: '?app=CalendarNotes' }}>
              <img src="assets/imgs/logos/Logo_CalendarNotes.png" alt={t('CalendarNotes')} title={t('CalendarNotes')} />
            </NavLink>
          </div>
          <div className="Titulo">
            <NavLink to={{ pathname: "/Apps", search: '?app=CalendarNotes' }}>{t('CalendarNotes')}</NavLink>
          </div>
          <div className="Descrip">
            <p>{t('TextPagApps_InfoCalendarNotes')}
              <br />{t('TextPagApps_InfoCalendarNotes1')}
              <br />{t('TextPagApps_InfoCalendarNotes2')}</p>
          </div>
        </li>

        <li className="Der">
          <div class="Imagen">
            <NavLink to={{ pathname: "/Apps", search: '?app=CryptApp' }}>
              <img src="assets/imgs/logos/Logo_CryptApp.png" alt={t('CryptApp')} title={t('CryptApp')} />
            </NavLink>
          </div>
          <div className="Titulo">
            <NavLink to={{ pathname: "/Apps", search: '?app=CryptApp' }}>{t('CryptApp')}</NavLink>
          </div>
          <div className="Descrip">
            <p>{t('TextPagApps_InfoCryptApp')}
              <br />{t('TextPagApps_InfoCryptApp1')}
              <br />{t('TextPagApps_InfoCryptApp2')}</p>
          </div>
        </li>

        <li className="Izq">
          <div className="Imagen">
            <NavLink to={{ pathname: "/Apps", search: '?app=FlashLight' }}>
              <img src="assets/imgs/logos/Logo_FlashLight.png" alt={t('FlashLight')} title={t('FlashLight')} />
            </NavLink>
          </div>
          <div className="Titulo">
            <NavLink to={{ pathname: "/Apps", search: '?app=FlashLight' }}>{t('FlashLight')}</NavLink>
          </div>
          <div className="Descrip">
            <p>{t('TextPagApps_InfoFlashLight')}
              <br />{t('TextPagApps_InfoFlashLight1')}</p>
          </div>
        </li>

        <li className="Der">
          <div className="Imagen">
            <NavLink to={{ pathname: "/Apps", search: '?app=InstantTraductor' }}>
              <img src="assets/imgs/logos/Logo_InstantTraductor.png" alt={t('InstantTraductor')} title={t('InstantTraductor')} />
            </NavLink>
          </div>
          <div className="Titulo">
            <NavLink to={{ pathname: "/Apps", search: '?app=InstantTraductor' }}>{t('InstantTraductor')}</NavLink>
          </div>
          <div className="Descrip">
            <p>{t('TextPagApps_InfoInstantTraductor')}
              <br />{t('TextPagApps_InfoInstantTraductor1')}
              <br />{t('TextPagApps_InfoInstantTraductor2')}</p>
          </div>
        </li>

        <li className="Izq">
          <div className="Imagen">
            <NavLink to={{ pathname: "/Apps", search: '?app=NoteApp' }}>
              <img src="assets/imgs/logos/Logo_NoteApp.png" alt={t('NoteApp')} title={t('NoteApp')} />
            </NavLink>
          </div>
          <div className="Titulo">
            <NavLink to={{ pathname: "/Apps", search: '?app=NoteApp' }}>{t('NoteApp')}</NavLink>
          </div>
          <div className="Descrip">
            <p>{t('TextPagApps_InfoNoteApp')}
              <br />{t('TextPagApps_InfoNoteApp1')}
              <br />{t('TextPagApps_InfoNoteApp2')}</p>
          </div>
        </li>

      </ul>
    </div>
  )
}