import CargarMultiIdiomas from 'i18next'
import { initReactI18next } from 'react-i18next'

// Importing translation files
import translationEN from "./ContFJsonIdiomas/en.json";
import translationES from "./ContFJsonIdiomas/es.json";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

let ListIdiomasDisponibles = JSON.parse('{"English": "en","Español": "es"}');

function DetectarIdiomaUsu() { // console.log("Idioma:",navigator.language);
  var idioDetec = navigator.language;
  if (idioDetec.length > 2) idioDetec = idioDetec.substr(0, 2);
  else if (idioDetec.length === 2) { }
  else idioDetec = "Null";

  if (idioDetec !== "Null") {
    for (var nombIdio in ListIdiomasDisponibles) { // console.log("Idioma: %s -> %s",nombIdio,ListIdiomasDisponibles[nombIdio]);
      if (ListIdiomasDisponibles[nombIdio] === idioDetec) { return nombIdio; }
    }
  }
  return "English";
}

function AutoCambIdio_IniWeb() { // console.log("AutoCambIdio_IniWeb")
  var languate = "en";
  var idioma = DetectarIdiomaUsu()
  //console.log("idioma -> " + idioma)

  if (idioma === 'English') languate = "en";
  else if (idioma === 'Español') languate = "es";
  else languate = "en";

  //console.log("IdioAutoSelec -> " + languate)
  return languate;
}

//i18N Initialization
CargarMultiIdiomas.use(initReactI18next)
  .init({
    resources,
    lng: AutoCambIdio_IniWeb(), //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });



export default CargarMultiIdiomas;
