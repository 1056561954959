import { useState } from 'react'
import './SelecIdiomas.css'
import { useTranslation } from "react-i18next";

export function CambIdio() {
  //Calling t and i18n method from useTranslation hook 
  const { i18n } = useTranslation(); // const { t, i18n } = useTranslation(); t si queremos añadir etiquetas para los Idiomas >>> {t('lbEs')}, {t('lbEn')}

  //Creating a method to change the language onChnage from select box
  const HandlerCambIdio = (e) => {
    const languageValue = e.target.value
    //console.log("languageValue -> "+languageValue)
    i18n.changeLanguage(languageValue);
  }

// +++ Auto Detectar Idioma +++  

  let idioAct="English"
  const {IniWeb, setIniWeb}= useState(false)
  let ListIdiomasDisponibles=JSON.parse('{"English": "en","Español": "es"}');

  function DetectarIdiomaUsu() { // console.log("Idioma:",navigator.language);
    var idioDetec=navigator.language;
    if(idioDetec.length>2) idioDetec=idioDetec.substr(0,2);
    else if(idioDetec.length===2) { }
    else idioDetec="Null";

    if(idioDetec!=="Null") {
      for(var nombIdio in ListIdiomasDisponibles) { // console.log("Idioma: %s -> %s",nombIdio,ListIdiomasDisponibles[nombIdio]);
        if(ListIdiomasDisponibles[nombIdio]===idioDetec) { return nombIdio; }
      }
    }

    setIniWeb(true);
    return "English";
  }

  if(!IniWeb) idioAct=DetectarIdiomaUsu()
// --- Auto Detectar Idioma ---

  return (
    <div className="SelecIdio">
      <div className="ImgFd_SelecIdio">
        {/* Select box to change language */}
        <select className="custom-select" onChange={HandlerCambIdio}>
          {idioAct === "English" ? <option value="en" selected >English</option> : <option value="en" >English</option>}
          {idioAct === "Español" ? <option value="es" selected >Español</option> : <option value="es" >Español</option>}          
        </select>
        {/* call name of the variable from  the translation.json file to t() method */}
      </div>
    </div>
  );
}