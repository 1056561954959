import React, { useState } from 'react'
import './Body.css'

// +++ MultiIdiomas +++
//import { useTranslation } from "react-i18next";

// +++ Web +++
import { PagIni } from '../../ElemWebs/PagIni/PagIni';
import { PagApps } from '../../ElemWebs/PagApps/PagApps';
import { PagVideoTutorials } from '../../ElemWebs/PagVideoTutorials/PagVideoTutorials';
import { PagKnowUs } from '../../ElemWebs/PagKnowUs/PagKnowUs';

// +++ Publi Google AdSense +++
import { PubliGoogleAdSense } from '../../Publi/PubliGoogle/PubliGoogleAdSense';

export function Body({ pagAct }) {
    //const { t } = useTranslation();

    var FSIni = CambFontSize_Dinamic();

    const [fontSize, setFontSize] = useState(FSIni)
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            setFontSize(CambFontSize_Dinamic())
            window.removeEventListener('resize', handleResize)
        }
    })

    function CambFontSize_Dinamic() {
        var FS = "15px"
        var tamAnchoVent = (((((window.innerWidth - 850) * 100) / 1070) * 3) / 100) + 15;
        if (tamAnchoVent > 17) FS = "17px";
        else if (tamAnchoVent < 15) FS = "15px";
        else FS = tamAnchoVent + "px";
        return FS;
    }

    return (
        <div className='ContCuerpoPag'>
            { /* <div>XY: {dimensions.width} x {dimensions.height} --- fontSize: {fontSize}  -- {pagAct} </div> */ }
            <div className="Ttex1" style={{ fontSize: fontSize }}>
                {pagAct == "Inicio" && <PagIni />}
                {pagAct == "Apps" && <PagApps />}
                {pagAct == "VideoTutorials" && <PagVideoTutorials />}
                {pagAct == "KnowUs" && <PagKnowUs />}
            </div>
            { /* <PubliGoogleAdSense/> // Para que funcione, Activar "Public/index.html" */ }
        </div >
    )
}

/*

{ pagAct=="Inicio" ? <PagIni /> : "" }          -> 2 Formas:
{ pagAct=="Apps" ? <PagIni /> : "" }                        Opc 1: con "Condicion ? Op1 : Op2"
{ pagAct=="VideoTutorials" && <PagIni /> }                 Opc 2: con "Condicion && Opc"
{ pagAct=="KnowUs" && <PagKnowUs /> }

 */