import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// +++ Cargar MultiIdiomas +++
import "./GestionIdiomas/MultiIdiomas"

import { Webs } from './Paginas/Inicio/Paginas';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
    <Webs/>
  </div>
);
