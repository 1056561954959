import React from 'react'
import './Footer.css'

import { useTranslation } from "react-i18next";

import { Link, NavLink } from 'react-router-dom';

export function Footer() {
    const { t } = useTranslation();

    return (
        <footer class="PiePagina">
            <br/>
                <div class='PiedePagina'>
                <NavLink to="/KnowUs">{t('lbContactar')}</NavLink> | <a href="/sitemap.xml">{t('lbSitemap')}</a>
            </div>
            <div class='CopyRigth'>
                Copyright &copy; { new Date().getFullYear() }  Flyjam Developer. All rights reserved.<br/>
            </div>
        </footer>
    )
}

// format(new Date(), 'YYYY')
