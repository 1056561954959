import React from 'react'
import './Paginas.css';

// +++ Route +++ (Para enrutar de un apartado a otro la pagina Web)
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Libreria React de Enrutamiento para Webs (Sin el dom, no serviria para Webs)

// +++ MultiIdiomas +++
//import { useTranslation } from "react-i18next";

// +++ Web +++
import { Header } from '../../BloqWebs/Header/Header';
import { Body } from '../../BloqWebs/Body/Body';
import { BlqCaractiristicas } from '../../BloqWebs/BlqCaracteristicas/BlqCaractiristicas';
import { Footer } from '../../BloqWebs/Footer/Footer';

// Auto Publi
import { AutoPubli_Transicion } from '../../Publi/AutoPubli/AutoPubli_Transicion';


export function Webs() {

  return (
    <BrowserRouter>
      <Header />
      < AutoPubli_Transicion />
      <Routes>
        <Route path='/' element={<Inicio />} />
        <Route path='/Index' element={<Inicio />} />
        <Route path='/Apps' element={<Apps />} />
        <Route path='/VideoTutorials' element={<VideoTutoriales />} />
        <Route path='/KnowUs' element={<QSomos />} />
        <Route path='*' element={<Inicio />} />
      </Routes>
      <BlqCaractiristicas/>
      <Footer />
    </BrowserRouter>
  );
}

export function Inicio() {

  return (
    <div className="Inicio">
      <Body pagAct="Inicio"/>
    </div>
  );
}

export function Apps() {

  return (
    <div className="Apps">
      <Body pagAct="Apps"/>
    </div>
  );
}

export function VideoTutoriales() {

  return (
    <div className="VideoTutorials">
      <Body pagAct="VideoTutorials"/>
    </div>
  );
}

export function QSomos() {  

  return (
    <div className="KnowUs">
      <Body pagAct="KnowUs"/>
    </div>
  );
}


// +++++++++++++++++++++++++++++
/*
export function SiteMap() {

  const WebSiteMap = [
    { loc: "http://www.flyjam.one/", lastmod: "2020-04-09T09:05:47+01:00", changefreq: "weekly", priority: "1.00" },
    { loc: "http://www.flyjam.one/index", lastmod: "2020-04-09T09:05:47+01:00", changefreq: "weekly", priority: "1.00" },
    { loc: "http://www.flyjam.one/App", lastmod: "2020-04-09T09:05:47+01:00", changefreq: "weekly", priority: "1.00" }
  ]

  return (
    <urlset>
      <br />
      {WebSiteMap.map((sm) => {
        return (
          <>
            <br />
            <url>
              <loc>{sm.loc}</loc>
              <lastmod>{sm.lastmod}</lastmod>
              <changefreq>{sm.changefreq}</changefreq>
              <priority>{sm.priority}</priority>
            </url>
          </>
        )
      })}
    </urlset>
  );
}
*/
// +++++++++++++++++++++++++++++
/*

return (
    <urlset xsi: schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
      {WebSiteMap.map((sm) => {
        return (
        <url>
          <loc>sm.loc</loc>
          <lastmod>sm.lastmod</lastmod>
          <changefreq>sm.changefreq</changefreq>
          <priority>sm.priority</priority>
        </url>
        )
      })}
    </urlset>
  );

  */