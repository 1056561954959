import React, { useState } from 'react'
import './BlqCaracteristicas.css';

// +++ MultiIdiomas +++
import { useTranslation } from "react-i18next";


export function BlqCaractiristicas() {

    const { t } = useTranslation();

    var FSIni=CambFontSize_Dinamic();
    var PdgIni=CambPadding_Dinamic();
    
    const [fontSize, setFontSize] = useState(FSIni) // Rango de 15 a 20
    const [myPadding, setPadding] = useState(PdgIni) // Rango de "0px 0px" a "0px 40px"

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            setFontSize(CambFontSize_Dinamic())
            setPadding(CambPadding_Dinamic())
            window.removeEventListener('resize', handleResize)
        }
    })

    function CambFontSize_Dinamic() {
        var FS="15px"
        var tamAnchoVent = (((((window.innerWidth - 850) * 100) / 1070) * 5) / 100) + 15;
        if (tamAnchoVent > 20) FS="20px";
        else if (tamAnchoVent < 15) FS="15px";
        else FS=tamAnchoVent + "px";
        return FS;
    }
    
    function CambPadding_Dinamic() {
        var Pdg="0px 0px"
        var tamAnchoVent = (((((window.innerWidth - 850) * 100) / 1070) * 40) / 100);
        if (tamAnchoVent > 40) Pdg="0px 40px";
        else if (tamAnchoVent < 0) Pdg="0px 0px";
        else Pdg="0px " + tamAnchoVent + "px";
        return Pdg;
    }

    return (
        <div className="BlCaracteristicas" style={{ fontSize: fontSize }} >
            { /* <div>XY: {dimensions.width} x {dimensions.height} --- fontSize: {fontSize} --- myPadding: {myPadding}</div> */ }
            <pre>
                <b>{t('TextPiePagCaracteristicas')}</b>
            </pre>
            <table>
                <tr>
                    <td style={{ 'padding': myPadding }}>
                        <pre>
                            <b>{t('TextPiePagCaracteristicas_Interfaz')}</b><br />
                            {t('TextPiePagCaracteristicas_Interfaz1')}<br />
                            {t('TextPiePagCaracteristicas_Interfaz2')}<br />
                        </pre>
                    </td>
                    <td style={{ 'padding': myPadding }}>
                        <pre>
                            <b>{t('TextPiePagCaracteristicas_Manejabilidad')}</b><br />
                            {t('TextPiePagCaracteristicas_Manejabilidad1')}<br />
                            {t('TextPiePagCaracteristicas_Manejabilidad2')}<br />
                            {t('TextPiePagCaracteristicas_Manejabilidad3')}<br />
                        </pre>
                    </td>
                    <td style={{ 'padding': myPadding }}>
                        <pre>
                            <b>{t('TextPiePagCaracteristicas_Usuarios')}</b><br />
                            {t('TextPiePagCaracteristicas_Usuarios1')}<br />
                            {t('TextPiePagCaracteristicas_Usuarios2')}<br />
                            {t('TextPiePagCaracteristicas_Usuarios3')}<br />
                            {t('TextPiePagCaracteristicas_Usuarios4')}<br />
                        </pre>
                    </td>
                    <td style={{ 'padding': myPadding }}>
                        <pre>
                            <b>{t('TextPiePagCaracteristicas_Gratis')}</b><br />
                            {t('TextPiePagCaracteristicas_Gratis1')}<br />
                            {t('TextPiePagCaracteristicas_Gratis2')}<br />
                        </pre>
                    </td>
                </tr>
            </table>
        </div >
    )
}

/* 
  Explicacion "tamAnchoVent": Son 2 reglas de 3 juntas.
      TamVent     %    TamFuente
  Max 1920px --- 100% --- 20px   --> Si el Tamaño Maximo Optimo de la Ventana son 1920px luego es el 100% en el cual el tamaño de la fuente Maximo sera de 20px
  Min  850px ---   0% --- 15px   --> Y el Tamaño Minimo Optimo de la Ventana son 850px, entonces el porcentaje cera 0 y el tamaño minimo de fuente sera 15px
         X         Y       Z

  Con esto obtenemos el Ancho de la ventana "X" y hallamos que tamaño fuente le corresponde:

      ((X-TamVentMin)*100)/(TamVentMax-TamVentMin) = (((window.innerWidth-850)*100)/1070)  => Nos da el Y% correspondiente
      ((X-     850  )*100)/(    1920  -  850     ) = (((window.innerWidth-850)*100)/1070)

  Una vez optenido el Y% con otra regla de 3 hallamos (entre el % y TamFuente) el valor que corresponde al "TamFuente" para segun que "TamVent"

      ((Y%*(TamFuenteMax-TamFuenteMin))/100)+(TamFuenteMin)  = ((Y%*5)/100)+15;
      
 ---- ---- ---- ---- ---- ----

  Explicacion "tamAnchoVent": Son 2 reglas de 3 juntas.
      TamVent     %    TamPadding
  Max 1920px --- 100% --- 40px   --> Si el Tamaño Maximo Optimo de la Ventana son 1920px luego es el 100% en el cual el tamaño del Padding Maximo sera de 20px
  Min  850px ---   0% ---  0px   --> Y el Tamaño Minimo Optimo de la Ventana son 850px, entonces el porcentaje cera 0 y el tamaño minimo del Padding sera 0px
         X         Y       Z

  Con esto obtenemos el Ancho de la ventana "X" y hallamos que tamaño de Padding le corresponde:

      ((X-TamVentMin)*100)/(TamVentMax-TamVentMin) = (((window.innerWidth-874)*100)/1043)  => Nos da el Y% correspondiente

  Una vez optenido el Y% con otra regla de 3 hallamos (entre el % y TamFuente) el valor que corresponde al "TamFuente" para segun que "TamVent"

      ((Y%*TamPadding)/100)  = ((Y%*40)/100);      
*/