import React from 'react'
import './PagVideoTutorials.css'

// +++ MultiIdiomas +++
import { useTranslation } from "react-i18next";

import { NavLink, useLocation } from 'react-router-dom'; // useParams

export function PagVideoTutorials() {
    
    // const { val } = useParams()

    const { search } = useLocation();
    let query = new URLSearchParams(search); // Para serializar los parametros
    //console.log(query)

    let appSelecUsu = query.get("app")
    //console.log(">>>" + appSelecUsu)

    return (
        <div className='PagInicio'>
            { /* <div>app: {appSelecUsu} </div> */ }
            <table>
                <tr>
                    <td className="lb01"></td>
                </tr>
                <tr>
                    <td className="centrar" colspan="2">
                        <div className="Ttex1">
                            {appSelecUsu == "LanguageTranslatorFast" && <LanguageTranslatorFast />}
                            {appSelecUsu == "CalendarNotes" && <CalendarNotes />}
                            {appSelecUsu == "CryptApp" && <CryptApp />}
                            {appSelecUsu == "NoteApp" && <NoteApp />}
                            {appSelecUsu == "InstantTraductor" && <InstantTraductor />}
                            {appSelecUsu !== "LanguageTranslatorFast" && appSelecUsu !== "CalendarNotes" && appSelecUsu !== "CryptApp" && appSelecUsu !== "NoteApp" && appSelecUsu !== "InstantTraductor" && <SelecVideoTuto />}
                            <br /><br /><br />
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    )
}

function LanguageTranslatorFast() {
    return (
        <>
        </>
    )
}

function CalendarNotes() {
    const { t } = useTranslation();

    return (
        <>
            <table className="BlPagVideoTuto">
                <tr>
                    <td colspan="2">
                        <pre><b className='Titulo' >{t('TextPagVideoTuto_CalendarNotes')}</b></pre>
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_CalendarNotes1')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/RtQdReTl1KE" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_CalendarNotes2')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/SnD_eMGugnU" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_CalendarNotes3')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/WggN9Yfjwn4" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_CalendarNotes4')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/EgV5DhlFeI4" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_CalendarNotes5')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/PueBmDzyFjg" width="640" height="360" frameborder="0"  allowfullscreen></iframe>
                        </div>
                    </td>
                </tr>
            </table>
        </>
    )
}

function CryptApp() {
    return (
        <>
        </>
    )
}

function NoteApp() {
    return (
        <>
        </>
    )
}

function InstantTraductor() {
    const { t } = useTranslation();

    return (
        <>
            <img alt="Instrucciones App Instant Traductor" title="Instrucciones App Instant Traductor" src="assets/imgs/Tuto/Instructions_Instant_Traductor.jpg" width="640" height="360" />
            <table className="BlPagVideoTuto">
                <tr>
                    <td colspan="2">
                        <pre><b className='Titulo' >{t('TextPagVideoTuto_InstantTraductor')}</b></pre>
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_InstantTraductor1')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/Gc0p84lA7pw" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_InstantTraductor2')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/m34attKx48o" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_InstantTraductor3')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/KLSk6qb7488" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                    <td>
                        <pre><b>{t('TextPagVideoTuto_InstantTraductor4')}</b></pre>
                        <div>
                            <iframe src="https://www.youtube.com/embed/DvpuqciyWW8" width="640" height="360" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </td>
                </tr>
            </table>
        </>
    )
}
function SelecVideoTuto() {
    const { t } = useTranslation();

    return (
        <table className="BlPagSelecVideoTuto">
            <tr>
                <td>
                    <NavLink to={{ pathname: "/VideoTutorials", search: '?app=LanguageTranslatorFast' }} >
                        <div className="RefApps">
                            <b>{t('LanguageTranslatorFast')}</b><br />
                            <img alt={t('LanguageTranslatorFast')} title={t('LanguageTranslatorFast')} src="/assets/imgs/logos/Logo_LanguageTranslatorFast.png" />
                        </div>
                    </NavLink>
                </td>
                <td>
                    <NavLink to={{ pathname: "/VideoTutorials", search: '?app=CalendarNotes' }} >
                        <div className="RefApps">
                            <b>{t('CalendarNotes')}</b><br />
                            <img alt={t('CalendarNotes')} title={t('CalendarNotes')} src="/assets/imgs/logos/Logo_CalendarNotes.png" />
                        </div>
                    </NavLink>
                </td>
                <td>
                    <NavLink to={{ pathname: "/VideoTutorials", search: '?app=CryptApp' }}>
                        <div className="RefApps">
                            <b>{t('CryptApp')}</b><br />
                            <img alt={t('CryptApp')} title={t('CryptApp')} src="/assets/imgs/logos/Logo_CryptApp.png" />
                        </div>
                    </NavLink>
                </td>
            </tr>
            <tr>
                <td>
                    <NavLink to={{ pathname: "/VideoTutorials", search: '?app=NoteApp' }} >
                        <div className="RefApps">
                            <b>{t('NoteApp')}</b><br />
                            <img alt={t('NoteApp')} title={t('NoteApp')} src="/assets/imgs/logos/Logo_NoteApp.png" />
                        </div>
                    </NavLink>
                </td>
                <td>
                    <NavLink to={{ pathname: "/VideoTutorials", search: '?app=InstantTraductor' }} >
                        <div className="RefApps">
                            <b>{t('InstantTraductor')}</b><br />
                            <img alt={t('InstantTraductor')} title={t('InstantTraductor')} src="/assets/imgs/logos/Logo_InstantTraductor.png" />
                        </div>
                    </NavLink>
                </td>
            </tr>
        </table>
    )
}
